export const IntroSecDetails = {
  heading1: "Hello",
  typeWritterHeadings: [
    "I'm Ed Greenwood", 
    "I'm a lifelong coder",
    "I'm a software generalist",
    "I'm an entrepeneur",
    "I'm an innovator",
  ],
  heading2: "based in Boston, Massachusetts.",
  btnText: "Download Resume",
};
export const AboutSecDetails = {
  image: {
    img: "images/about-me.jpg",
  },
  information: {
    title1: "About Me",
    name: "Ed Greenwood",
    desc1:
      "Emerging technology: Blockchain, Crypto, GenAI, Tokenization, Web3, NFTs, AR & Metaverses | Technical Fellow | Consultant | Full-stack Developer | Software Generalist | Entrepreneur & Founder | Innovator",
    desc2:
      "My background is in computer science and software. This includes more than 20 years of experience as an entrepreneur, developer, and consultant. For the past ten years, I have been researching emerging and disruptive technology including blockchain, cryptocurrency, smart contracts, tokenization, web3, NFTs, AR, spatial computing, metaverses, AI, GenAI, and ML. I conduct “technical deep dives”, build prototypes, and write papers (before information is widely known) to innovate new products and services, share results, and make recommendations.",
  },
  moreAbout: [
    {
      num: 20,
      title: "Years Experience",
    },
    {
      num: 10,
      title: "Emerging Technologies",
    },
    {
      num: 100,
      title: "ComProjects Done",
    },
  ],
};

export const ServicesDetails = {
  heading: {
    title: "My Services",
    text: "How I can help take your next project to new heights! Thousands of clients have procured exceptional results while working with Me.",
  },
  details: [
    {
      name: "Blockchain",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-palette",
    },
    {
      name: "Web Design",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-desktop",
    },
    {
      name: "UI/UX Design",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "App Design & Develop",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "Business Analysis",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-chart-area",
    },
    {
      name: "SEO Marketing",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
      icon: "fas fa-bullhorn",
    },
  ],
};

export const MyWorking_Skills = {
  heading: {
    title: "Why I'm Different?",
    text: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
  },
  video: {
    vdo: "http://www.youtube.com/watch?v=7e90gBu4pas",
  },
  working: [
    {
      name: "Unique Design",
      icon: "fas fa-fingerprint",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Fully Customisable",
      icon: "fas fa-cog",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Different Layout",
      icon: "fas fa-layer-group",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Responsive Layout",
      icon: "fas fa-mobile-alt",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Boxed & Wide Layouts",
      icon: "fas fa-expand-alt",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Extensive Documentation",
      icon: "far fa-life-ring",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
  ],
  skills: [
    {
      skill: "Branding & Design",
      percent: 95,
    },
    {
      skill: "Web Development",
      percent: 65,
    },
    {
      skill: "Business Analysis",
      percent: 80,
    },
    {
      skill: "Digital Marketing",
      percent: 75,
    },
  ],
};

export const CallToActionDetails = {
  heading: "Start a project with Me today!",
  subHeading: "I am Available for Freelancer.",
  btnText: "Hire Me",
};

export const WorkProcessDetails = {
  heading: {
    title: "Work Process",
    text: "Simple work process of build your design. Delivering work within time and budget which meets client’s requirements is our moto.",
  },
  WDetails: [
    {
      sno: 1,
      name: "Planning & Consulting",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 2,
      name: "Content",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 3,
      name: "Conception",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 4,
      name: "Design & Development",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 5,
      name: "Final discussion",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 6,
      name: "Delivery & Launch",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
  ],
};

export const OurClientsDetails = {
  heading: {
    title: "Our Clients",
    text: "See what my happy clients say about me. I'm proud to have my clients.",
  },
  clients: [
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      summary:
        " Easy to use, reasonably priced simply dummy text of theprinting and typesetting industry. Quidam lisque persiusinteresset his et, in quot quidam possim iriure. simply dummy text of the printing and typesetting industry.",
    },
    {
      name: "Patric Cary",
      img: "images/testimonial/client-sm-2.jpg",
      exp: "Freelancer from USA",
      summary:
        " “I am happy Working with printing and typesetting industry.Quidam lisque persius interesset his et, in quot quidampersequeris essent possim iriure. simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Dennis Jacques",
      img: "images/testimonial/client-sm-3.jpg",
      exp: "iDeal Inc",
      summary:
        " “Only trying it out since a few days. But up to now excellent.Seems to work flawlessly. priced simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Chris Tom",
      img: "images/testimonial/client-sm-4.jpg",
      exp: "Company CEO from UK",
      summary:
        " “I have used them twice now. Good rates, very efficientservice and priced simply dummy text of the printing andtypesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”",
    },
  ],
  posters: [
    {
      id: 1,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 2,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 3,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 4,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 5,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 6,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 7,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 8,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 9,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 10,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 11,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 12,
      img: "images/clients/client-logo2.png",
    },
  ],
};

export const ContactDetails = [
  {
    icon: "fas fa-map-marker-alt",
    title: "Visit Us",
    p1: "145 Murphy Canyon Rd.",
    p2: " Suite 100-18, San Diego CA 2028",
  },
  {
    icon: "fas fa-phone-alt",
    title: "Call Us Now",
    p1: "Phone: (+060) 9898980098",
    p2: " Fax: (+060) 8898880088",
  },
  {
    icon: "fas fa-envelope",
    title: "Inquiries",
    p1: "info@kenilpatel.com",
    p2: " Mon to Fri (10 am – 8 pm)",
  },
];

export const socialIcons = [
  {
    title: "Twitter",
    icon: "fab fa-twitter",
    href: "https://twitter.com/harnishdesign/",
    className: "social-icons-twitter",
  },
  {
    title: "Facebook",
    icon: "fab fa-facebook-f",
    href: "http://www.facebook.com/harnishdesign/",
    className: "social-icons-facebook",
  },
  {
    title: "Linkedin",
    icon: "fab fa-linkedin-in",
    href: "http://www.linkedin.com/",
    className: "social-icons-linkedin",
  },
  {
    title: "Dribbble",
    icon: "fab fa-dribbble",
    href: "http://www.dribbble.com/harnishdesign/",
    className: "social-icons-dribbble",
  },
  {
    title: "GitHub",
    icon: "fab fa-github",
    href: "http://www.github.com/",
    className: "social-icons-github",
  },
];